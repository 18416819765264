import * as React from 'react'
import { Link } from 'gatsby'
import Seo from '../components/seo'

const NotFoundPage = () => {
  return (
    <main>
      <section>
        <div className="container">
          <div className="row py-5">
            <div className="col">
              <h1 className="h3 mt-5 mb-4">Page not found</h1>
              <p className="mb-3">
                Sorry{' '}
                <span role="img" aria-label="Pensive emoji">
                  😔
                </span>{' '}
                we couldn’t find what you were looking for.
              </p>
              <Link to="/" className="btn btn-secondary">
                Return to Juan Ortiz Guitar
              </Link>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export const Head = () => <Seo title="Not Found" />

export default NotFoundPage
